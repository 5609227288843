import * as Sentry from '@sentry/react'
import { Route } from 'react-router-dom'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import { getPharmacyId, getTenantId } from '~/lib/tenantMetadata'
import { getEnvironmentName } from '~/util/envMethods'
import { getEnterpriseCode } from '~/util/enterpriseMethods'
import { scrubPII } from './helpers'

type InitMonitoringProps = {
  history: History
}

function scrubEvent(event: Sentry.Event) {
  const {
    environment,
    event_id,
    level,
    platform,
    sdkProcessingMetadata,
    sdk,
    type,
    timestamp,
    modules,
    ...eventPayload // breadcrumb, contexts, transaction, exception, extra, tags, etc.
  } = event

  // We should scrub only properties that might contain PII/PHI
  const scrubbedEventPayload = scrubPII({ target: eventPayload })

  // Other technical properties can be be kept as is
  return {
    ...scrubbedEventPayload,
    environment,
    event_id,
    level,
    platform,
    sdkProcessingMetadata,
    sdk,
    type,
    timestamp,
    modules,
  }
}

function getSentryTracesSampleRate() {
  const tracesSampleRate = parseFloat(
    process.env.SENTRY_TRACES_SAMPLE_RATE ?? ''
  )

  if (isNaN(tracesSampleRate)) {
    return undefined
  }

  return tracesSampleRate
}

export function initMonitoring({ history }: InitMonitoringProps) {
  const sentryDsn = process.env.SENTRY_DSN

  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      ],
      environment: getEnvironmentName(),
      tracesSampleRate: getSentryTracesSampleRate(),
      tracePropagationTargets: [
        process.env.NODE_GRAPHQL_ENDPOINT || 'localhost',
        process.env.GRAPHQL_ENDPOINT || 'localhost',
      ],
      beforeBreadcrumb: excludeGraphQLFetch,
      beforeSend: scrubEvent,
      beforeSendTransaction: scrubEvent,
    })

    const scope = Sentry.getCurrentScope()

    const tenantId = getTenantId()
    scope.setTag('tenantId', tenantId ?? 'unknown')

    const pharmacyId = getPharmacyId(true)
    if (pharmacyId) {
      scope.setTag('pharmacyId', pharmacyId)
    }

    const enterprise = getEnterpriseCode()
    if (enterprise) {
      scope.setTag('enterprise', enterprise)
    }

    const params = new URLSearchParams(window.location.search)
    if (params.has('webview') && params.get('webview') === 'true') {
      scope.setTag('webview', 'true')
    }
  }
}

export const withMonitoringProfiler = Sentry.withProfiler

export const MonitoredRoute = Sentry.withSentryRouting(Route)

export const reportException = Sentry.captureException
